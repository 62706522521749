<template>
  <div class="login">
    <div class="bg">
      <img class="f" src="../assets/image/login/bg.png">
    </div>
    <LoginPhone ref="LoginPhone"></LoginPhone>
    <LoginEmail ref="LoginEmail"></LoginEmail>
    <LoginName ref="LoginName"></LoginName>
  </div>
</template>

<script>
import { visitAdd} from '../api/login'
import LoginPhone from '../components/LoginPhone'
import LoginName from '../components/LoginName'
import LoginEmail from '../components/LoginEmail'


export default {
  data() {
    return {
      userInfo: {},
    }
  },
  created() {
    visitAdd()
    setTimeout(()=>{
      this.init()
    },500)
  },
  components: {
    LoginPhone,
    LoginName,
    LoginEmail
  },
  methods:{
    openEmail(){
      this.$refs.LoginEmail.openEmail()
    },
    openPhone(){
      this.$refs.LoginPhone.openPhone()
    },
    openName(userInfo){
      this.$refs.LoginName.open(userInfo)
    },
    init(){
      let userInfo = this.$store.state.userInfo
      let PhoneMobile = userInfo.PhoneMobile
      let Email= userInfo.Email
      if (PhoneMobile) {
        let Name = userInfo.Name
        let GotoTimeText = userInfo.GotoTime
        let AlumnusText = userInfo.Alumnus
        if (Name && GotoTimeText && AlumnusText) {
           this.$router.push('/zju/exhibit')
        } else {
          if ('GotoTime' in userInfo && 'Alumnus' in userInfo) {
            this.viewName = true
            if (Name) {
              this.name = Name
            }
          } else {
            this.$store.state.userInfo = {}
          }
        }
      }else if (Email){
        let Name = userInfo.Name
        let GotoTimeText = userInfo.GotoTime
        let AlumnusText = userInfo.Alumnus
        if (Name && GotoTimeText && AlumnusText) {
           this.$router.push('/zju/exhibit')
        } else {
          if ('GotoTime' in userInfo && 'Alumnus' in userInfo) {
            this.viewName = true
            if (Name) {
              this.name = Name
            }
          } else {
            this.$store.state.userInfo = {}
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.login {

  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .bg {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    img {
      width: 100%;
      position: absolute;
    }

  }
}

</style>
